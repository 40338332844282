/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ListResult } from '../models/ListResult'
import type { MemberPosition } from '../models/MemberPosition'
import type { Task } from '../models/Task'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class PositionMasterService {
  /**
   * Search for positions
   * @returns any OK
   * @throws ApiError
   */
  public static getV1CciAdminMasterPositions({
    departmentName,
    positionName,
    positionType,
    startAtGte,
    endAtLte,
    keyword,
    companyNameContains,
    expand,
    cciCode,
    page = 1,
    pageSize = 10,
    sort,
    fields,
  }: {
    departmentName?: string
    positionName?: string
    positionType?: string
    startAtGte?: string
    endAtLte?: string
    keyword?: string
    companyNameContains?: string
    /**
     * (Comma separated) Which related fields should be expanded
     */
    expand?: string
    cciCode?: string
    page?: number
    pageSize?: number
    /**
     * (Comma separated) Which fields should be sorted. Used `-` for DESC.
     */
    sort?: string
    /**
     * (Comma separated) Which fields should be returned
     */
    fields?: string
  }): CancelablePromise<
    ListResult & {
      Items?: Array<MemberPosition>
    }
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/cci/admin/master/positions',
      query: {
        department_name: departmentName,
        position_name: positionName,
        position_type: positionType,
        start_at__gte: startAtGte,
        end_at__lte: endAtLte,
        keyword: keyword,
        company_name__contains: companyNameContains,
        expand: expand,
        cci_code: cciCode,
        page: page,
        page_size: pageSize,
        sort: sort,
        fields: fields,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    })
  }

  /**
   * Add positions
   * @returns MemberPosition OK
   * @throws ApiError
   */
  public static postV1CciAdminMasterPositions({
    requestBody,
  }: {
    requestBody: MemberPosition
  }): CancelablePromise<MemberPosition> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/cci/admin/master/positions',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    })
  }

  /**
   * Get position detail
   * @returns MemberPosition OK
   * @throws ApiError
   */
  public static getV1CciAdminMasterPositions1({
    id,
    expand,
  }: {
    id: string
    /**
     * (Comma separated) Which related fields should be expanded
     */
    expand?: string
  }): CancelablePromise<MemberPosition> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/cci/admin/master/positions/{id}',
      path: {
        id: id,
      },
      query: {
        expand: expand,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    })
  }

  /**
   * update position data
   * @returns MemberPosition OK
   * @throws ApiError
   */
  public static putV1CciAdminMasterPositions({
    id,
    requestBody,
  }: {
    id: string
    requestBody: MemberPosition
  }): CancelablePromise<MemberPosition> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/cci/admin/master/positions/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    })
  }

  /**
   * delete position data
   * @returns MemberPosition OK
   * @throws ApiError
   */
  public static deleteV1CciAdminMasterPositions({
    id,
    requestBody,
  }: {
    id: string
    requestBody: MemberPosition
  }): CancelablePromise<MemberPosition> {
    return __request(OpenAPI, {
      method: 'DELETE',
      url: '/v1/cci/admin/master/positions/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    })
  }

  /**
   * update positions data
   * @returns Task OK
   * @throws ApiError
   */
  public static putV1CciAdminMasterPositionsUpdatePositions({
    requestBody,
  }: {
    requestBody: {
      positions: Array<MemberPosition>
      employee_id: string
      company_id: string
    }
  }): CancelablePromise<Task> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/cci/admin/master/positions/update_positions',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    })
  }
  /**
   * Fetch positions settings data
   * @returns ListResult OK
   * @throws ApiError
   */
  public static getV1CciAdminMasterPositionsPositionsSetting({
    masterTypeCode,
    masterCode,
  }: {
    masterTypeCode: string
    masterCode?: string
  }): CancelablePromise<Array<MemberPosition>> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/cci/admin/master/positions/positions_setting',
      query: {
        master_type_code: masterTypeCode,
        master_code: masterCode,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    })
  }
}
