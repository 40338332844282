/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { Company } from '../models/Company'
import type { ListResult } from '../models/ListResult'

import type { CancelablePromise } from '../core/CancelablePromise'
import { OpenAPI } from '../core/OpenAPI'
import { request as __request } from '../core/request'

export class CompanyMasterService {
  /**
   * search companies
   * @returns any OK
   * @throws ApiError
   */
  public static getV1CciAdminMasterCompanies({
    companyCode,
    keyword,
    cciCode,
    page = 1,
    pageSize = 10,
    permissions,
    sort,
    fields,
  }: {
    companyCode?: string
    keyword?: string
    cciCode?: string
    page?: number
    pageSize?: number
    permissions?: string
    /**
     * (Comma separated) Which fields should be sorted. Used `-` for DESC.
     */
    sort?: string
    /**
     * (Comma separated) Which fields should be returned
     */
    fields?: string
  }): CancelablePromise<
    ListResult & {
      Items?: Array<Company>
    }
  > {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/cci/admin/master/companies',
      query: {
        company_code: companyCode,
        keyword: keyword,
        cci_code: cciCode,
        page: page,
        page_size: pageSize,
        sort: sort,
        fields: fields,
        permissions: permissions,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    })
  }

  /**
   * create a company
   * @returns Company OK
   * @throws ApiError
   */
  public static postV1CciAdminMasterCompanies({ requestBody }: { requestBody: Company }): CancelablePromise<Company> {
    return __request(OpenAPI, {
      method: 'POST',
      url: '/v1/cci/admin/master/companies',
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    })
  }

  /**
   * Get company detail
   * @returns Company OK
   * @throws ApiError
   */
  public static getV1CciAdminMasterCompanies1({ id }: { id: string }): CancelablePromise<Company> {
    return __request(OpenAPI, {
      method: 'GET',
      url: '/v1/cci/admin/master/companies/{id}',
      path: {
        id: id,
      },
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    })
  }

  /**
   * Update company detail
   * @returns Company OK
   * @throws ApiError
   */
  public static putV1CciAdminMasterCompanies({
    id,
    requestBody,
  }: {
    id: string
    requestBody: Company
  }): CancelablePromise<Company> {
    return __request(OpenAPI, {
      method: 'PUT',
      url: '/v1/cci/admin/master/companies/{id}',
      path: {
        id: id,
      },
      body: requestBody,
      mediaType: 'application/json',
      errors: {
        400: `Error occurred`,
        401: `Error occurred`,
        403: `Error occurred`,
        404: `Error occurred`,
        500: `Error occurred`,
      },
    })
  }
}
