import { LoadingOutlined } from '@ant-design/icons'
import { Spin } from 'antd'

type CommonButtonProps = {
  type: 'primary' | 'secondary' | 'indigo' | 'link' | 'neutral' | 'cancel' | 'danger' | 'primary-dark'
  size?: 'small' | 'normal' | 'big'
  minWidth?: string
  className?: string
  colorLoading?: string
  loading?: boolean
  submit?: boolean
  onClick?: (e?: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void
} & Record<string, any>

export const CommonButton = ({
  minWidth = '200px',
  size = 'normal',
  type,
  children,
  className,
  loading = false,
  disabled = false,
  submit = true,
  colorLoading = 'white',
  ...props
}: React.PropsWithChildren<CommonButtonProps>) => {
  const antIcon = <LoadingOutlined style={{ fontSize: 24, color: colorLoading }} spin />
  return (
    <>
      <button
        className={`button ${type} ${className || ''} ${size}`}
        {...props}
        type={submit ? 'submit' : 'button'}
        disabled={loading || disabled}
      >
        {loading ? <Spin indicator={antIcon} /> : children}
      </button>
      <style jsx>{`
        .button {
          font-weight: 400;
          font-size: 16px;
          line-height: 24px;
          display: inline-flex;
          align-items: center;
          justify-content: center;
          height: 40px;
          transition: all 0.3s;
        }

        .button:hover {
          cursor: pointer;
        }

        .button:not(.min-w-auto) {
          min-width: 200px;
          min-width: ${minWidth};
        }

        .b-radius-sm {
          border-radius: 5px;
        }

        .small {
          height: 35px;
        }

        .button:hover {
          opacity: 0.8;
        }
        .primary {
          background-color: #00c4c4;
          color: #fff;
          border: 1px solid #00c4c4;
          box-shadow: 0px 4px 8px #00000065;
        }
        .primary-dark {
          background-color: #2f9191; /* Slightly darker than #00c4c4 */
          color: #fff;
          border: 1px solid #2f9191;
          box-shadow: 0px 4px 8px #00000065;
        }
        .secondary {
          background-color: #ffba00;
          color: #ffffff;
        }
        .danger {
          background-color: #ff0000;
          color: #ffffff;
        }
        .indigo {
          background-color: #4fe8e8;
          color: #ffffff;
          border: 1px solid ##90e7e7;
          box-shadow: 0px 4px 8px #00000065;
        }

        .neutral {
          background-color: #693f00;
          color: #fff;
          border: 2px solid #693f00;
        }

        .link {
          background-color: #fff;
          color: #00c4c4;
          border: 3px solid #fff;
          font-weight: bold;
        }

        .cancel {
          background-color: #fff;
          color: #693f00;
          font-size: 14px;
          font-weight: bold;
          border: 3px solid #fff;
        }
        button:disabled,
        button[disabled] {
          border: 1px solid #cccccc;
          background-color: #cccccc;
          color: #666666;
          cursor: not-allowed;
        }
      `}</style>
    </>
  )
}
